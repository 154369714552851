import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@gatsbyjs/reach-router';
import getImage from '../../util/getImage';
import isEmpty from '../../util/isEmpty';

interface SEOSchema {
  title?: string;
  description?: string;
  image?: string;
  canonicalAddress?: string;
  datePublished?: string;
  dateModified?: string;
  author?: string;
  no_index?: boolean;
}

const SEO = ({
  title,
  description,
  image,
  canonicalAddress: customCanonicalAddress,
  dateModified,
  datePublished,
  author,
  no_index = false,
}: SEOSchema) => {
  const { site, twitterImg } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            APP_DESCRIPTION
            siteUrl
            APP_NAME
            APP_LOCALE
          }
        }
        twitterImg: file(relativePath: { eq: "twitter_card.png" }) {
          childImageSharp {
            gatsbyImageData(
              formats: [PNG]
              width: 4000
              layout: CONSTRAINED
              quality: 60
              webpOptions: { quality: 60 }
              breakpoints: [4000]
            )
          }
        }
      }
    `
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  searchParams.delete('utm_source');
  searchParams.delete('utm_medium');
  searchParams.delete('utm_campaign');
  searchParams.delete('utm_id');

  const imgDefault = getImage(twitterImg.childImageSharp?.gatsbyImageData);

  const getMetaImage = () => {
    if (!isEmpty(image as string)) {
      return image;
    }

    if (imgDefault?.images?.fallback) {
      return `https://www.elsewhen.com${imgDefault.images.fallback.src}`;
    }

    return undefined;
  };

  const metaTitle = title || site.siteMetadata.APP_NAME;
  const metaDescription = description || site.siteMetadata.APP_DESCRIPTION;
  const canonicalAddress = isEmpty(customCanonicalAddress)
    ? `${site.siteMetadata.siteUrl}${location.pathname}${searchParams}`
    : customCanonicalAddress;

  const shouldIncludeArticleSchema = () => {
    return (
      datePublished &&
      dateModified &&
      location.pathname.split('/').length > 3 &&
      (location.pathname.includes('/blog') ||
        location.pathname.includes('/work'))
    );
  };

  const isHomePage = location.pathname === '/';

  /** enablePageIndexing reflects value of No Index toggle on cms */
  const enablePageIndexing = no_index === false;

  return (
    <Helmet
      defaultTitle={site.siteMetadata?.APP_NAME}
      titleTemplate={isHomePage ? `%s` : `%s | ${site.siteMetadata?.APP_NAME}`}
    >
      {!enablePageIndexing && <meta name="robots" content="noindex" />}
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalAddress} />
      <meta property="twitter:url" content={canonicalAddress} />
      <link rel="canonical" href={canonicalAddress} />
      <meta property="og:image" content={getMetaImage()} />
      <meta property="twitter:image" content={getMetaImage()} />
      <meta name="twitter:site" content="@Elsewhen" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@Elsewhen" />
      <meta name="twitter:domain" content="elsewhen.com" />
      <meta name="og:site_name" content={site.siteMetadata?.APP_NAME} />
      <meta property="og:locale" content={site?.siteMetadata?.APP_LOCALE} />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:width" content="1200" />
      <meta name="theme-color" content="#161314" />
      {shouldIncludeArticleSchema() && (
        <script type="application/ld+json">
          {`
                  {
                    "@context": "https://schema.org",
                    "@type": "NewsArticle",
                    "headline": "${metaTitle}",
                    "datePublished": "${datePublished}",
                    "dateModified": "${dateModified}",
                    "image": "${getMetaImage()}"${
            isEmpty(author)
              ? ''
              : `,
                    "author": "${author}"`
          }
                  }
          `}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
